import { message } from 'ant-design-vue';

const Info = (params) => {
    message.info(params);
}

const Success = (params) => {
    message.success(params);
}

const Error = (params) => {
    message.error(params);
}

const Warning = (params) => {
    message.warning(params);
}


export default {
    Info,
    Success,
    Error,
    Warning,
}
<template>
    <div class="login-warp" style="background: url('images/Login/bg.png') no-repeat center center;background-size:cover;">
        <!-- <img class="bottom-bg" src="images/Login/bottom.png" />
        <img class="logo" src="images/Login/logo.png" /> -->
        <a-form layout="inline">
            <a-form-item>
                <a-input v-model="UserName" size="large" placeholder="账号/手机号">
                </a-input>
            </a-form-item>
            <a-form-item>
                <a-input-password v-model="Password" size="large" type="password" placeholder="密码">
                </a-input-password>
            </a-form-item>
            <a-form-item>
                <a-button type="primary" size="large" @click="Submit">
                    登录
                </a-button>
            </a-form-item>
        </a-form>
    </div>
</template>
<script>
export default {
    data() {
        return {
            UserName: "",
            Password: ""
        }
    },
    methods: {
        Submit() {

            if(!this.UserName) {
                Site.Modal.Alert("请输入账号或手机号");
                return;
            }

            if(!this.Password) {
                Site.Modal.Alert("请输入密码");
                return;
            }

            if(this.SubmitLock) { return; }

            this.SubmitLock = true;

            Cgl.Net.Request.CallEasyItf("Rzs_UserLogin", {
                username: this.UserName,
                password: Cgl.Safe.Md5(this.Password)
            }).then((data)=>{

                if(data.IsSuccess === "1") {
                    if (data.shop_list&&data.shop_list.length > 0) {
                        Site.ShopList=data.shop_list; // 保存店铺列表到内部数组中

                    }
                    this.$router.push("/");
                }
                else {
                    Site.Modal.Alert(data.ErrMsg);
                }

            }, ()=>{
                Site.Modal.Alert("网络问题，请刷新后重试");
            }).finally(() =>{
                this.SubmitLock = false;
            });
        }
    }
}
</script>
<style>
.login-warp {
    width: 100%;
    height: 100%;
    background: #fff;
}
.login-warp .ant-form {
    position: absolute;
    bottom: 3.75rem;
    text-align: center;
    width: 100%;
}
.login-warp .bottom-bg {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    height: 16rem;
}

.login-warp .logo {
    position: absolute;
    width: 22rem;
    left: 50%;
    top: 15%;
    margin-left: -11rem;
}

</style>
import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '../views/Login';
import GoodsCategory from '../views/GoodsCategory';
import GoodsList from '../views/GoodsList';
import QuotationBillList from '../views/QuotationBillList';
import QuotationBillDtl from '../views/QuotationBillDtl';

Vue.use(VueRouter);

//解决路由重复报错问题
const RouterPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return RouterPush.call(this, location).catch(error=> error);
}

const Routes = [
	{ 
		path: '/', 
		component: GoodsCategory
	},
	{
		path: '/login',
		name: 'login',
		component: Login
	},
	{ 
		path: '/goodslist',
		name: 'goodslist',
		component: GoodsList
	},
	{ 
		path: '/quotationbilllist',
		name: 'quotationbilllist',
		component: QuotationBillList
	},
	{ 
		path: '/quotationbill',
		name: 'quotationbill',
		component: QuotationBillDtl
	},
	{ 
		path: '/goodscategory', 
		name: 'goodscategory',
		component: GoodsCategory
	}
];

const Router = new VueRouter({
	mode: 'hash',
	base: process.env.BASE_URL,
	routes: Routes
});

Router.beforeEach((to, from, next) => {
	next();
});

Router.afterEach(() => {});

window.Router = Router;

export default Router;
<template>
	<div class="input-number-warp">
		<a-input-group compact>
			<a-button @click="MinusValue" :disabled="Value <= 1"><a-icon type="minus"/></a-button>
			<!--  :disabled="true" -->
			<a-input v-model="Value" @change="ChangeNumberEvent" :style="{ width: inputWidth, textAlign: 'center' }" />
			<a-button @click="PlusValue"><a-icon type="plus"/></a-button>
		</a-input-group>
	</div>
</template>
<script>
export default {
	name: "cg-input-number",
	props: {
		value: {
			type: Number | String,
			default: 0,
		},
		inputWidth: {
			type: String,
			default: "3rem",
		},
		min: {
			type: Number,
			default: 0,
		},
		goodsId: {
			type: String,
			default: "",
		},
	},
	data() {
		return {
			Value: "",
			OldValue: "",
		};
	},
	watch: {
		value() {
			this.SetValue(this.value);
		},
		Value() {
			this.$emit("input", this.GetValue());
			this.$emit("change", this.GetValue());

			this.OldValue = this.Value;
		},
	},
	created() {
		this.SetValue(this.value);
	},
	methods: {
		SetValue(value) {
			value = value * 1;

			if (value === undefined || value === null || value === "") {
				value = 0;
			}

			this.Value = value;
		},
		GetValue() {
			return this.Value;
		},
		PlusValue() {
			let _value = this.Value;

			this.Value++;

			this.$emit("plus", { goodsId: this.goodsId, updateValue: 1, value: this.Value, oldValue: _value });
		},
		MinusValue() {
			let _value = this.Value;

			this.Value = Math.max(this.min, this.Value - 1);

			this.$emit("minus", { goodsId: this.goodsId, updateValue: 1, value: this.Value, oldValue: _value });
		},
		ChangeNumberEvent() {
			// console.log("ChangeNumberEvent :>> ", this.Value, this.OldValue);

			// 商品变化的数量
			const _updateValue = this.Value - this.OldValue;
			this.$emit(_updateValue > 0 ? "plus" : "minus", {
				goodsId: this.goodsId,
				updateValue: Math.abs(_updateValue),
				value: this.Value,
				oldValue: this.OldValue,
			});
		},
	},
};
</script>
<style>
.input-number-warp {
	display: inline-block;
	width: 8.875rem;
}
</style>

<template>
    <div class="quotation-bill-affix">
        <a-button type="primary" size="large" @click="ShowDrawer" style="height: auto;">
            <img src="images/order-icon.png" />
            订单
        </a-button>

        <a-drawer class="quotation-bill-affix-drawer" placement="right" :visible="DrawerVisible" width="80%" :destroyOnClose="true" @close="CloseDrawer">

            <template slot="title">
                <div style="margin-bottom: 1rem;">
                    <span style="font-size: 1.5rem; font-weight: bold;">报价单</span>
                    <span v-show="QuotationBillDtl.quotation_bill_id" style="margin-left: 2rem;">
                        ID：{{ QuotationBillDtl.quotation_bill_no }}
                    </span>
                </div>
                <a-form>
                    <a-row>
                        <a-col :span="5">
                            <a-form-item label="客户姓名：" :required="true" style="margin-bottom: 0;">
                                <a-input v-model="Form.user_name" placeholder="请输入">
                                </a-input>
                            </a-form-item>
                        </a-col>
                        <a-col :span="5" :offset="1">
                            <a-form-item label="手机：" :required="true" style="margin-bottom: 0;">
                                <a-input v-model="Form.user_tel" type="number" :maxLength="11" placeholder="请输入">
                                </a-input>
                            </a-form-item>
                        </a-col>
                        <a-col :span="5" :offset="1">
                            <a-form-item label="微信：" style="margin-bottom: 0;">
                                <a-input v-model="Form.user_wx" placeholder="请输入">
                                </a-input>
                            </a-form-item>
                        </a-col>
                        <a-col :span="5" :offset="1">
                            <a-form-item label="销售顾问：" :required="true" style="margin-bottom: 0;">
                                <a-select v-model="Form.sales_employee_id" placeholder="请选择" style="width: 100%;">
                                    <a-select-option value="">
                                        请选择
                                    </a-select-option>
                                    <a-select-option v-for="(item, index) in SalesEmployeeList" :key="index" :value="item.sales_employee_id">
                                        {{ item.sales_employee_name }}
                                    </a-select-option>
                                </a-select>
                            </a-form-item>
                        </a-col>
                    </a-row>
                </a-form>
            </template>

            <a-spin :spinning="PageLoading">

                <a-table size="small"
                    :showHeader="false"
                    :pagination="false" 
                    :columns="Columns" 
                    :data-source="GoodsList" 
                    :row-key="(record, index) => index" 
                    style="margin-bottom: 5rem;">

                    <template slot="pic_path" slot-scope="text, record">
                        <cg-bg-img :src="record.pic_path" style="width:8rem; height:6rem;" />
                    </template>

                    <template slot="goods_desc" slot-scope="text, record">
                        <div class="goods-desc-text">
                            {{ record.goods_desc }}
                            <span v-if="record.goods_type_dtl_id" style="color:#ff4d4f;">
                                [{{ record.goods_type_dtl_desc }}]
                            </span>
                        </div>
                        <div class="goods-type-text">
                            {{ record.type }}
                        </div>
                        <div>编号：{{ record.goods_code }}</div>
                        <div class="goods-sell-price-text">
                            ￥ {{ FormatMoney(record.sell_price) }}
                        </div>
                    </template>

                    <template slot="qty" slot-scope="text, record" style="position: relative;">
                        <cg-input-number :value="record.qty" :goodsId="record.goods_id" @minus="MinusGoodsQty" @plus="PlusGoodsQty" />
                        <a-button type="link" ghost icon="close" 
                            style="color: rgba(0, 0, 0, 0.45);position: absolute;top: 0;right: -.5rem;" 
                            @click="DeleteQuotationGoods(record)" />
                    </template>
                </a-table>

                <cg-footer v-show="QuotationBillDtl.quotation_bill_id" style="width: 80%;right: 0;">
                    <template slot="left">
                        <span style="font-size: 1.25rem;">合计：</span>
                        <span class="goods-sell-price-text" style="font-size: 1.5rem;">
                            ￥ {{ FormatMoney(QuotationBillDtl.total_amount) }}
                        </span>
                    </template>
                    <template slot="right">
                        <a-button type="danger" size="large" icon="save" @click="SubmitQuotationBill(false)">
                            清空并暂存
                        </a-button>
                        <a-button type="primary" size="large" icon="file" style="margin-left: 1rem;" @click="SubmitQuotationBill(true)">
                            查看报价详情
                        </a-button>
                    </template>
                </cg-footer>
            </a-spin>

        </a-drawer>
    </div>
</template>
<script>
import CgInputNumber from './base/InputNumber.vue';
import CgBgImg from './base/BgImg.vue';
import CgFooter from "./base/Footer.vue";

const Columns = [
    {
        title: '商品图片',
        dataIndex: 'pic_path',
        scopedSlots: { customRender: 'pic_path' },
        align: "center"
    },
    {
        title: '商品描述',
        dataIndex: 'goods_desc',
        scopedSlots: { customRender: 'goods_desc' },
    },
    {
        title: '数量',
        dataIndex: 'qty',
        scopedSlots: { customRender: 'qty' },
        align: "center",
    }
];

export default {
    name: "quotation-bill-affix",
    components: { CgInputNumber, CgBgImg, CgFooter },
    data() {
        return {
            PageLoading: false,
            DrawerVisible: false,
            Columns: Columns,
            QuotationBillDtl: Site.GetNewQuotationBillDtl(),
            SalesEmployeeList: [],
            Form: {
                "user_name": "",
                "user_tel": "",
                "user_wx": "",
                "sales_employee_id": ""
            },
            GoodsList: []
        }
    },
    computed: {},
    created() {

        Site.GetSalesEmployeeList().then((list)=>{
            this.SalesEmployeeList = list;
        });
    },
    methods: {
        ShowDrawer() {

            let _defaultForm = {
                "user_name": "",
                "user_tel": "",
                "user_wx": "",
                "sales_employee_id": ""
            };

            this.GetQuotationBillDtl().then(()=>{

                let _form = {};

                for(let _key in _defaultForm) {
                    _form[_key] = this.QuotationBillDtl[_key];
                }

                this.Form = _form;

                this.DrawerVisible = true;
            });
        },
        CloseDrawer() {
            this.DrawerVisible = false;
        },
        SetQuotationBillDtl(data) {

            this.QuotationBillDtl = Site.GetNewQuotationBillDtl(data);

            Site.AffixQuotationBillID = this.QuotationBillDtl.quotation_bill_id;

            this.GoodsList = this.QuotationBillDtl.good_list;
        },
        //获取报价单详情
        GetQuotationBillDtl() {

            this.PageLoading = true;

            return new Promise((resolve)=>{

                Cgl.Net.Request.CallEasyItf("Rzs_GetQuotationBillDtl", {
                    quotation_bill_id: Site.AffixQuotationBillID
                }).then((data)=>{

                    if(data.IsSuccess == "1") {

                        this.SetQuotationBillDtl(data.data);

                        resolve();
                    }
                    else {
                        Site.Modal.Alert(data.ErrMsg);
                    }
                }, ()=>{
                    Site.Modal.Alert("网络问题，请刷新后重试");
                }).finally(()=>{
                    this.PageLoading = false;
                });
            });
        },
        //减商品数量
        MinusGoodsQty(event) {

            // console.log('PlusGoodsQty :>> ', event);

            if(!event.goodsId) return console.error('MinusGoodsQty fail :> 减少商品数量 goodsId 为空');
            if(event.updateValue < 1) return console.error('MinusGoodsQty fail :> 减少商品数量 updateValue 为空');

            this.PageLoading = true;
            
            Cgl.Net.Request.CallEasyItf("Rzs_DeductionQuotationGoods", {
                quotation_bill_id: Site.AffixQuotationBillID,
                goods_id: event.goodsId,
                qty: event.updateValue
            }).then((data)=>{

                if(data.IsSuccess == "1") {
                    this.GetQuotationBillDtl();
                }
                else {
                    this.PageLoading = false;
                    Site.Modal.Alert(data.ErrMsg);
                }
            }, ()=>{
                this.PageLoading = false;
                Site.Modal.Alert("网络问题，请刷新后重试");
            });
        },
        //加商品数量
        PlusGoodsQty(event) {

            // console.log('PlusGoodsQty :>> ', event);
            
            if(!event.goodsId) return console.error('MinusGoodsQty fail :> 减少商品数量 goodsId 为空');
            if(event.updateValue < 1) return console.error('MinusGoodsQty fail :> 减少商品数量 updateValue 为空');

            this.PageLoading = true;
            Cgl.Net.Request.CallEasyItf("Rzs_AddQuotationGoods", {
                quotation_bill_id: Site.AffixQuotationBillID,
                goods_id: event.goodsId,
                qty: event.updateValue
            }).then((data)=>{

                if(data.IsSuccess == "1") {
                    this.GetQuotationBillDtl();
                }
                else {
                    this.PageLoading = false;
                    Site.Modal.Alert(data.ErrMsg);
                }
            }, ()=>{
                this.PageLoading = false;
                Site.Modal.Alert("网络问题，请刷新后重试");
            });
        },
        //删除报价单商品
        DeleteQuotationGoods(goods) {

            Site.Modal.Confirm("确认删除该商品？", ()=>{

                this.PageLoading = true;
                
                Cgl.Net.Request.CallEasyItf("Rzs_DeleteQuotationGoods", {
                    quotation_bill_id: Site.AffixQuotationBillID,
                    goods_id: goods.goods_id,
                    // goods_type_dtl_id: goods.goods_type_dtl_id,
                }).then((data)=>{

                    if(data.IsSuccess == "1") {
                        this.GetQuotationBillDtl();
                    }
                    else {
                        this.PageLoading = false;
                        Site.Modal.Alert(data.ErrMsg);
                    }
                }, ()=>{
                    this.PageLoading = false;
                    Site.Modal.Alert("网络问题，请刷新后重试");
                });
            });

        },
        //提交报价单
        SubmitQuotationBill(isShowDtl) {

            if(!this.Form.user_name) {
                Site.Modal.Alert("请输入客户姓名");
                return;
            }

            if(!this.Form.user_tel) {
                Site.Modal.Alert("请输入手机号");
                return;
            }

            if(!/^[1][0-9]{10}$/.test(this.Form.user_tel)) {
                Site.Modal.Alert("请输入正确的手机号");
                return;
            }

            if(!this.Form.sales_employee_id) {
                Site.Modal.Alert("请选择销售顾问");
                return;
            }

            if(this.SubmitLock) { return; }

            this.SubmitLock = true;

            Cgl.Net.Request.CallEasyItf("Rzs_UpdateQuotationBill", {
                quotation_bill_id: Site.AffixQuotationBillID,
                user_name: this.Form.user_name,
                user_tel: this.Form.user_tel,
                user_wx: this.Form.user_wx,
                sales_employee_id: this.Form.sales_employee_id,
                status: 1
            }).then((data)=>{

                if(data.IsSuccess == "1") {

                    let _quotationBillID = Site.AffixQuotationBillID;

                    //关闭浮层
                    this.CloseDrawer();

                    //提交后清空
                    this.SetQuotationBillDtl();

                    if(isShowDtl) {
                        Site.ToQuotationBillDtl(_quotationBillID);
                    }
                }
                else {
                    Site.Modal.Alert(data.ErrMsg);
                }
            }, ()=>{
                Site.Modal.Alert("网络问题，请刷新后重试");
            }).finally(()=>{
                this.SubmitLock = false;
            });
        }

    }
}
</script>
<style>
.quotation-bill-affix {
    position: fixed;
    right: 0;
    bottom: 6.25rem;
}
.quotation-bill-affix img{
    display: block;
    width: 2.875rem;
    margin: .5rem;
}
</style>
<template>
    <cg-header>
        <div slot="left" style="text-align: right;">
            <!-- <a-button type="link" size="large" style="color: #333;" @click="SearchQuotationBill">
                <img src="images/search-icon.png" style="width: 1.875rem; position: absolute;" />
                <span style="margin-left: 2rem;">报价单</span>
            </a-button> -->
            <a-button class="cg-header-button" type="link" size="large" icon="file-search" @click="ToQuotationBillList()">
                报价单
            </a-button>
        </div>
        <div slot="center" style="text-align: center;">
            <a-input v-model="Keyword" size="large" placeholder="请输入搜索关键字" 
                style="width: 80%;" @pressEnter="ToQuotationBillList(Keyword)">
                <a-icon slot="prefix" type="search" />
            </a-input>
            <a-popover title="搜索提示" trigger="click">
                <template slot="content">
                    输入订单编号、客户姓名、手机号、微信号搜索
                </template>
                <a-button type="link" size="large" icon="info-circle" style="color: #999;">
                </a-button>
            </a-popover>
        </div>
        <div slot="right">
            <a-button class="cg-header-button" type="link" size="large" icon="rollback" @click="ToGoodsCategory">
                {{ShopList&&ShopList.length>0?'返回首页':'继续选购'}}
            </a-button>
        </div>
    </cg-header>
</template>
<script>
import Site from "../core";
import CgHeader from "./base/Header";

export default {
    name: "quotation-bill-header",
    components: { CgHeader },
    data() {
        return {
            Keyword: "",
            ShopList:Site.ShopList
        }
    },
    computed: {
        // ShopList(){
        //     return Site.ShopList
        // }
    },
   async created() {
        await Site.VerifyUserLogin();
        

        this.ShopList=Site.ShopList
    },
    methods: {
        ToGoodsCategory() {
            Site.ToGoodsCategory();
        },
        ToQuotationBillList(keyword) {
            Site.ToQuotationBillList(keyword);
        }
    }
}
</script>
<style>

</style>
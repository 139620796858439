<template>
    <footer class="footer-warp">
        <div class="left">
            <slot name="left"></slot>
        </div>
        <div class="right">
            <slot name="right"></slot>
        </div>
    </footer>
</template>
<script>
export default {
    name: "cg-footer"
}
</script>
<style>
.footer-warp {
    background: #fff;
    padding: 1rem 1.5rem;
    position: fixed;
    width: 100%;
    bottom: 0;
    display: flex;
    z-index: 999;
    border-top: 1px solid rgb(235, 237, 240);
}

.footer-warp .left {
    flex: 1;
}
</style>
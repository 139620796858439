<template>
  <div class="quotation-bill-dtl-warp cg-page">
    <quotation-bill-header />

    <a-spin :spinning="PageLoading">
      <a-page-header :ghost="false" style="border: 1px solid rgb(235, 237, 240)">
        <template slot="title">报价单</template>
        <template slot="subTitle">
          <div style="font-size: 1rem">
            <span>ID：{{ QuotationBillDtl.quotation_bill_no }}</span>
            <span style="margin-left: 1.5rem">{{ QuotationBillDtl.cmn_createdate }}</span>
            <span style="margin-left: 1.5rem"
              >销售门店：{{ QuotationBillDtl.shop_name }}</span
            >
          </div>
        </template>

        <template slot="extra">
          <quotation-bill-status :status="QuotationBillDtl.status" />
        </template>

        <a-form :colon="false">
          <a-row>
            <a-col :span="4">
              <a-form-item :required="true" label="姓名：">
                <a-input v-model="Form.user_name" placeholder="请输入"> </a-input>
              </a-form-item>
            </a-col>
            <a-col :span="4" :offset="1">
              <a-form-item :required="true" label="手机：">
                <a-input v-model="Form.user_tel" placeholder="请输入"> </a-input>
              </a-form-item>
            </a-col>
            <a-col :span="4" :offset="1">
              <a-form-item label="微信：">
                <a-input v-model="Form.user_wx" placeholder="请输入"> </a-input>
              </a-form-item>
            </a-col>
            <a-col :span="4" :offset="1">
              <a-form-item label="邮箱：">
                <a-input v-model="Form.user_email" placeholder="请输入"> </a-input>
              </a-form-item>
            </a-col>
            <a-col :span="4" :offset="1">
              <a-form-item :required="true" label="销售顾问：">
                <a-select
                  v-model="Form.sales_employee_id"
                  placeholder="请选择"
                  style="width: 100%"
                >
                  <a-select-option
                    v-for="(item, index) in SalesEmployeeList"
                    :key="index"
                    :value="item.sales_employee_id"
                  >
                    {{ item.sales_employee_name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>

          <a-row v-show="ShowMoreForm">
            <a-col :span="4">
              <a-form-item :required="true" label="发货地址：">
                <a-select v-model="Form.province_id" style="width: 100%">
                  <a-select-option
                    v-for="(item, index) in ProvinceList"
                    :key="index"
                    :value="item.value"
                  >
                    {{ item.label }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="4" :offset="1">
              <a-form-item>
                <span slot="label" style="color: #fff">城市</span>
                <a-select v-model="Form.city_id" style="width: 100%">
                  <a-select-option
                    v-for="(item, index) in CityList"
                    :key="index"
                    :value="item.value"
                  >
                    {{ item.label }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="4" :offset="1">
              <a-form-item>
                <span slot="label" style="color: #fff">区</span>
                <a-select v-model="Form.county_id" style="width: 100%">
                  <a-select-option
                    v-for="(item, index) in CountyList"
                    :key="index"
                    :value="item.value"
                  >
                    {{ item.label }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="9" :offset="1">
              <a-form-item label="预计发货时间：">
                <a-date-picker
                  v-model="Form.about_send_date"
                  valueFormat="YYYY-MM-DD"
                  placeholder="请选择"
                  style="width: 100%"
                />
              </a-form-item>
            </a-col>
            <a-col :span="11">
              <a-form-item :required="true" label="详细地址：">
                <a-textarea v-model="Form.address"> </a-textarea>
              </a-form-item>
            </a-col>
            <a-col :span="12" :offset="1">
              <a-form-item label="备注：">
                <a-textarea v-model="Form.remark"> </a-textarea>
              </a-form-item>
            </a-col>
            <a-col :span="4">
              <a-form-item>
                <a-card style="width: 10rem; height: 10rem">
                  <img
                    v-if="QuotationBillDtl.rdy_quotation_pdf_path"
                    slot="cover"
                    style="width: 100%"
                    :src="GetQRCodePath(QuotationBillDtl.rdy_quotation_pdf_path)"
                  />
                </a-card>
              </a-form-item>
            </a-col>
            <a-col :span="19" :offset="1">
              <a-form-item
                :label="
                  QuotationBillDtl.rdy_quotation_pdf_path
                    ? '已生成标价单PDF'
                    : '未生成标价单PDF'
                "
              >
                <div style="margin: 0.5rem 0">
                  <a-button
                    type="primary"
                    size="large"
                    :icon="IsAllowSave ? 'save' : 'file-pdf'"
                    @click="SaveAndGeneratePDF"
                  >
                    {{ IsAllowSave ? "保存生成报价单PDF" : "生成报价单PDF" }}
                  </a-button>
                  <a-button
                    type="dashed"
                    size="large"
                    v-if="ShopList && ShopList.length <= 0"
                    @click="SubmitQuotationBillDeclare"
                    style="margin-left: 2rem"
                  >
                    <a-badge status="processing" text="申报总部" />
                  </a-button>
                </div>
                <a
                  target="_blank"
                  v-if="QuotationBillDtl.rdy_quotation_pdf_path"
                  :href="QuotationBillDtl.rdy_quotation_pdf_path"
                >
                  打开报价单PDF
                </a>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>

        <template slot="footer">
          <div style="text-align: center">
            <a-button
              size="large"
              shape="circle"
              :icon="ShowMoreForm ? 'up' : 'down'"
              @click="ShowMoreForm = !ShowMoreForm"
              style="top: 1.5rem"
            />
          </div>
        </template>
      </a-page-header>

      <!--商品列表-->
      <main class="cg-container cg-main">
        <a-table
          size="small"
          :pagination="false"
          :columns="GoodsColumns"
          :data-source="GoodsList"
          :row-selection="RowSelection"
          :row-key="(record) => record.goods_id"
        >
          <template slot="pic_path" slot-scope="text, record">
            <cg-bg-img
              :src="record.pic_path"
              style="width: 8rem; height: 6rem; display: inline-block"
            />
          </template>
          <template slot="goods_desc" slot-scope="text, record">
            <div class="goods-desc-text">
              {{ record.goods_desc }}
              <span v-if="record.goods_type_dtl_id" style="color: #ff4d4f">
                [{{ record.goods_type_dtl_desc }}]
              </span>
            </div>
            <div class="goods-type-text">
              {{ record.type }}
            </div>
            <div>编号：{{ record.goods_code }}</div>
          </template>
          <template slot="sell_price" slot-scope="text, record">
            <div class="goods-sell-price-text">
              ￥ {{ FormatMoney(record.sell_price) }}
            </div>
          </template>
          <template slot="qty" slot-scope="text, record">
            <span
              v-if="QuotationBillDtl.is_payment || (ShopList && ShopList.length > 0)"
              >{{ record.qty }}</span
            >
            <cg-input-number
              v-else
              :value="record.qty"
              :goodsId="record.goods_id"
              @minus="MinusGoodsQty"
              @plus="PlusGoodsQty"
            />
          </template>
          <template slot="amount" slot-scope="text, record">
            <div class="goods-sell-price-text">￥ {{ FormatMoney(record.amount) }}</div>
          </template>
          <div
            slot="footer"
            style="display: flex; padding: 0 1rem; font-size: 1.125rem; line-height: 3rem"
          >
            <div class="left" style="flex: 1">
              合计：<span class="goods-sell-price-text" style="margin-right: 1.5rem">
                ￥ {{ FormatMoney(QuotationBillDtl.total_amount) }}
              </span>
              <span v-show="QuotationBillDtl.discount_amount > 0">
                折后：<span class="goods-sell-price-text">
                  ￥ {{ FormatMoney(QuotationBillDtl.discount_amount) }}
                </span>
              </span>
            </div>
            <div class="right">
              <a-checkbox
                size="large"
                :checked="QuotationBillDtl.is_payment"
                @change="ConfirmPayment"
              >
                <span style="font-size: 1.125rem">已付款</span>
              </a-checkbox>
              签名：
              <a-button size="large" @click="ShowShowSignatureModal">
                <span style="opacity: 0">签名：</span>
                <cg-bg-img
                  :src="QuotationBillDtl.user_signed_pic_path"
                  style="width: 100%; height: 100%; position: absolute; left: 0; top: 0"
                />
              </a-button>
            </div>
          </div>
        </a-table>
      </main>

      <cg-footer>
        <template slot="left">
          <a-button type="primary" size="large" @click="ReturnPrevPage">
            返回上一页
          </a-button>
        </template>
        <template slot="right">
          <!--确认-->
          <a-button
            v-show="IsAllowSave"
            type="primary"
            size="large"
            icon="save"
            @click="SaveQuotationBill()"
          >
            保存
          </a-button>
          <!--复制-->
          <a-button
            v-if="IsAllowSave && !QuotationBillDtl.is_payment"
            type="primary"
            size="large"
            icon="rollback"
            style="margin-left: 1rem"
            @click="CopyQuotationBill"
          >
            继续选购
          </a-button>
          <!--导出-->
          <a-button
            type="primary"
            size="large"
            :icon="IsAllowSave ? 'save' : 'file-pdf'"
            style="margin-left: 1rem"
            @click="SaveAndGeneratePDF"
          >
            {{ IsAllowSave ? "保存生成报价单PDF" : "生成报价单PDF" }}
          </a-button>
        </template>
      </cg-footer>
    </a-spin>

    <!--报价单浮层-->
    <a-modal v-model="PDFQRCodeVisible" title="您的报价单已生成" :footer="null">
      <div style="text-align: center; line-height: 2rem">
        <a-card style="width: 10rem; display: inline-block">
          <img
            v-if="QuotationBillDtl.rdy_quotation_pdf_path"
            slot="cover"
            style="width: 100%"
            :src="GetQRCodePath(QuotationBillDtl.rdy_quotation_pdf_path)"
          />
        </a-card>
        <div>请用微信扫一扫获取报价单PDF</div>
        <a :href="QuotationBillDtl.rdy_quotation_pdf_path" target="_blank"
          >打开报价单PDF</a
        >
      </div>
    </a-modal>

    <!--申请报价浮层-->
    <a-modal v-model="DeclareQRCodeVisible" title="总部申请报价链接已生成" :footer="null">
      <div style="text-align: center; line-height: 2rem">
        <a-card style="width: 10rem; display: inline-block">
          <img
            slot="cover"
            :src="DeclareData.declare_qr_code_url"
            style="width: 100%; padding: 0.625rem"
          />
        </a-card>
        <div>请用微信扫一扫获取申请链接地址</div>
        <div style="margin-top: 1.5rem">
          <span
            class="cg-rec-no"
            v-for="(ch, index) in DeclareData.quotation_bill_declare_rec_no.split('')"
            :key="index"
          >
            {{ ch }}
          </span>
        </div>
        <div>申请编号</div>
      </div>
    </a-modal>

    <!--签名浮层-->
    <a-modal
      v-model="SignatureVisible"
      title="您的签名"
      width="800px"
      @ok="ConfirmSignature"
    >
      <canvas ref="SignatureCanvas" style="width: 100%" />
    </a-modal>
  </div>
</template>
<script>
import SignaturePad from "signature_pad";
import QuotationBillHeader from "../../components/QuotationBillHeader";
import QuotationBillStatus from "../../components/QuotationBillStatus";
import CgInputNumber from "../../components/base/InputNumber";
import CgBgImg from "../../components/base/BgImg";
import CgFooter from "../../components/base/Footer";
import Site from "../../core";
const Columns = [
  {
    title: "技术单页",
    dataIndex: "pic_path",
    scopedSlots: { customRender: "pic_path" },
    align: "center",
  },
  {
    title: "商品信息",
    dataIndex: "goods_desc",
    scopedSlots: { customRender: "goods_desc" },
  },
  {
    title: "单价",
    dataIndex: "sell_price",
    scopedSlots: { customRender: "sell_price" },
    align: "center",
  },
  {
    title: "数量",
    dataIndex: "qty",
    scopedSlots: { customRender: "qty" },
    align: "center",
  },
  {
    title: "合计",
    dataIndex: "amount",
    scopedSlots: { customRender: "amount" },
    align: "center",
  },
];

const DefaultForm = {
  user_name: "",
  user_tel: "",
  user_wx: "",
  user_email: "",
  sales_employee_id: "",
  province_id: "",
  city_id: "",
  county_id: "",
  about_send_date: "",
  address: "",
  remark: "",
};

export default {
  components: {
    QuotationBillHeader,
    QuotationBillStatus,
    CgFooter,
    CgInputNumber,
    CgBgImg,
  },
  data() {
    return {
      PageLoading: true,
      PageLoadingTip: "加载中...",
      ShowMoreForm: false,
      SignatureVisible: false,
      GoodsColumns: Columns,
      SelectedGoodsKeys: [],
      PDFQRCodeVisible: false,
      DeclareQRCodeVisible: false,
      QuotationBillDtl: Site.GetNewQuotationBillDtl(),
      SalesEmployeeList: [],
      ProvinceList: [{ value: "", label: "省份" }],
      CityList: [{ value: "", label: "城市" }],
      CountyList: [{ value: "", label: "区" }],
      Form: Object.assign({}, DefaultForm),
      DeclareData: {
        quotation_bill_declare_rec_id: "",
        quotation_bill_declare_rec_no: "",
        declare_qr_code_url: "",
      },
      IsAllowSave: false,
      ShopList: Site.ShopList,
    };
  },
  computed: {
    QuotationBillID() {
      return this.$route.query.quotation_bill_id || "";
    },
    RowSelection() {
      return {
        selectedRowKeys: this.SelectedGoodsKeys,
        onChange: (selectedRowKeys) => {
          this.SelectedGoodsKeys = selectedRowKeys;
        },
      };
    },
    GoodsList() {
      return this.QuotationBillDtl.good_list;
    },
  },
  watch: {
    "Form.province_id": function () {
      var _setCityDefaultVal = () => {
        this.Form.city_id = "";

        this.CityList = [{ value: "", label: "城市" }];
      };

      if (this.Form.province_id == "") {
        this.Form.province_id = this.ProvinceList[0].value;

        this.Form.province_id == "" && _setCityDefaultVal();
      } else {
        this.GetCityList(this.Form.province_id).then(() => {
          var _city = this.CityList.find((item) => item.value === this.Form.city_id);

          if (this.Form.city_id == "" || !_city) {
            this.Form.city_id = this.CityList[0].value;
          }
        });
      }
    },
    "Form.city_id": function () {
      var _setCountyDefaultVal = () => {
        this.Form.county_id = "";

        this.CountyList = [{ value: "", label: "请选择" }];
      };

      if (this.Form.province_id == "") {
        this.Form.city_id = this.CityList[0].value;

        this.Form.city_id == "" && _setCountyDefaultVal();
      } else {
        this.GetCountyList(this.Form.city_id).then(() => {
          var _county = this.CountyList.find(
            (item) => item.value === this.Form.county_id
          );

          if (this.Form.county_id === "" || !_county) {
            this.Form.county_id = this.CountyList[0].value;
          }
        });
      }
    },
  },
  async created() {
    await Site.VerifyUserLogin();
    this.ShopList = Site.ShopList;
    console.log("this.ShopList", this.ShopList);
    this.SetQuotationBillDtl();

    Site.GetSalesEmployeeList(true, Site.ShopID).then((list) => {
      this.SalesEmployeeList = list;
    });

    await this.GetProvinceList();

    await this.GetQuotationBillDtl();

    let _form = {};

    for (let _key in DefaultForm) {
      _form[_key] = this.QuotationBillDtl[_key];
    }

    this.Form = _form;
  },
  methods: {
    ShowLoading(text) {
      this.PageLoadingTip = text || "加载中...";
      this.PageLoading = true;
    },
    HideLoading() {
      this.PageLoading = false;
    },
    //获取二维码路径
    GetQRCodePath(url) {
      let _rootUrl = location.origin;

      if (process.env.NODE_ENV === "development") {
        _rootUrl = "http://vzugsell.nn.cagoe.com";
      }

      return `${_rootUrl}/QrCode.aspx?text=${url}`;
    },
    //设置报价单信息
    SetQuotationBillDtl(data) {
      this.QuotationBillDtl = Site.GetNewQuotationBillDtl(data);

      let _bool = { True: true, False: false, 1: true, 0: false };

      this.QuotationBillDtl["is_payment"] = _bool[this.QuotationBillDtl["is_payment"]];
      console.log("!this.shopList", !this.ShopList);
      this.IsAllowSave =
        !(this.QuotationBillDtl.status == "3" || this.QuotationBillDtl.status == "4") &&
        this.ShopList.length <= 0;

      if (this.QuotationBillDtl.status == "4") {
        Site.Modal.Alert("该订单已作废，不允许修改！", () => {
          this.ReturnPrevPage();
        });
      }
    },
    //获取报价单详情
    GetQuotationBillDtl() {
      this.ShowLoading();

      return new Promise((resolve) => {
        Cgl.Net.Request.CallEasyItf("Rzs_GetQuotationBillDtl", {
          quotation_bill_id: this.QuotationBillID,
        })
          .then((data) => {
            if (data.IsSuccess == "1") {
              this.SetQuotationBillDtl(data.data);

              resolve();
            } else {
              Site.Modal.Alert(data.ErrMsg);
            }
          })
          .finally(() => {
            this.HideLoading();
          });
      });
    },
    //减商品数量
    MinusGoodsQty(event) {
      if (!event.goodsId)
        return console.error("MinusGoodsQty fail :> 减少商品数量 goodsId 为空");
      if (event.updateValue < 1)
        return console.error("MinusGoodsQty fail :> 减少商品数量 updateValue 为空");

      this.ShowLoading();

      Cgl.Net.Request.CallEasyItf("Rzs_DeductionQuotationGoods", {
        quotation_bill_id: this.QuotationBillID,
        goods_id: event.goodsId,
        qty: event.updateValue,
      }).then(
        (data) => {
          if (data.IsSuccess == "1") {
            this.GetQuotationBillDtl();
          } else {
            this.HideLoading();
            Site.Modal.Alert(data.ErrMsg);
          }
        },
        () => {
          this.HideLoading();
          Site.Modal.Alert("网络问题，请刷新后重试");
        }
      );
    },
    //加商品数量
    PlusGoodsQty(event) {
      if (!event.goodsId)
        return console.error("MinusGoodsQty fail :> 减少商品数量 goodsId 为空");
      if (event.updateValue < 1)
        return console.error("MinusGoodsQty fail :> 减少商品数量 updateValue 为空");

      this.ShowLoading();

      Cgl.Net.Request.CallEasyItf("Rzs_AddQuotationGoods", {
        quotation_bill_id: this.QuotationBillID,
        goods_id: event.goodsId,
        qty: event.updateValue,
      }).then(
        (data) => {
          if (data.IsSuccess == "1") {
            this.GetQuotationBillDtl();
          } else {
            this.HideLoading();
            Site.Modal.Alert(data.ErrMsg);
          }
        },
        () => {
          this.HideLoading();
          Site.Modal.Alert("网络问题，请刷新后重试");
        }
      );
    },
    //获取省份列表
    GetProvinceList() {
      return new Promise((resolve) => {
        Cgl.Net.Request.CallEasyItf("Rzs_GetProvinceList").then((dat) => {
          var _provinceList = [];

          var _data =
            dat.data && dat.data.length > 0
              ? dat.data
              : [{ province_id: "", province_desc: "省份" }];

          for (var _i = 0; _i < _data.length; _i++) {
            _provinceList.push({
              value: _data[_i].province_id,
              label: _data[_i].province_desc,
            });
          }

          this.ProvinceList = _provinceList;

          resolve(dat);
        });
      });
    },
    //获取城市列表
    GetCityList(provinceID) {
      return new Promise((resolve) => {
        Cgl.Net.Request.CallEasyItf("Rzs_GetCityList", {
          province_id: provinceID,
        }).then((dat) => {
          var _cityList = [];

          var _data =
            dat.data && dat.data.length > 0
              ? dat.data
              : [{ city_id: "", city_desc: "城市" }];

          for (var _i = 0; _i < _data.length; _i++) {
            _cityList.push({
              value: _data[_i].city_id,
              label: _data[_i].city_desc,
            });
          }

          this.CityList = _cityList;

          resolve(dat);
        });
      });
    },
    //获取县区列表
    GetCountyList: function (cityID) {
      return new Promise((resolve) => {
        Cgl.Net.Request.CallEasyItf("Rzs_GetCountyList", {
          city_id: cityID,
        }).then((dat) => {
          var _countyList = [];

          var _data =
            dat.data && dat.data.length > 0
              ? dat.data
              : [{ county_id: "", county_desc: "区" }];

          for (var _i = 0; _i < _data.length; _i++) {
            _countyList.push({
              value: _data[_i].county_id,
              label: _data[_i].county_desc,
            });
          }

          this.CountyList = _countyList;

          resolve(dat);
        });
      });
    },
    //返回上一页
    ReturnPrevPage() {
      this.$router.back();
    },
    //复制报价单并选购
    CopyQuotationBill() {
      Cgl.Net.Request.CallEasyItf("Rzs_QuotationBillCopy", {
        quotation_bill_id: this.QuotationBillID,
        type: 2,
      }).then((data) => {
        if (data.IsSuccess == "1") {
          Site.AffixQuotationBillID = data.quotation_bill_info.quotation_bill_id;

          Site.ToGoodsCategory();
        } else {
          Site.Modal.Alert(data.ErrMsg);
        }
      });
    },
    //确认付款
    ConfirmPayment() {
      if (!this.IsAllowSave) {
        return;
      }

      if (this.QuotationBillDtl.is_payment) {
        this.QuotationBillDtl.is_payment = false;
      }
      //没有签名先签名，再勾选；否则直接勾选
      else if (!this.QuotationBillDtl.user_signed_pic_path) {
        this.ConfirmingPayment = true;
        this.ShowShowSignatureModal();
      } else {
        this.QuotationBillDtl.is_payment = true;
      }
    },
    //显示签名浮层
    ShowShowSignatureModal() {
      console.log(1);
      console.log("this.IsAllowSave", this.IsAllowSave);
      if (!this.IsAllowSave) {
        return;
      }

      this.SignatureVisible = true;

      this.$nextTick(() => {
        if (!this.SignaturePad) {
          let _canvas = this.$refs.SignatureCanvas;

          _canvas.width = _canvas.offsetWidth;
          _canvas.height = _canvas.offsetWidth / 2;

          this.SignaturePad = new SignaturePad(_canvas);
        } else {
          this.SignaturePad.clear();
        }
      });
    },
    //确认签名
    ConfirmSignature() {
      this.ShowLoading();

      Cgl.Net.Request.Send(Cgl.Net.GetInterfaceUrl() + "?method=Upload", {
        SavePath: "/Upload/UserSigned/",
        IsAutoCreateFolder: "1",
        IsSaveRealFileName: "0",
        InputName: "ImageData",
        ImageData: this.SignaturePad.toDataURL(),
      })
        .then((data) => {
          if (data.IsSuccess == "1") {
            this.QuotationBillDtl.user_signed_pic_path = "/Cg" + data.Path;

            //在确认付款中时，设为已付款
            if (this.ConfirmingPayment) {
              this.QuotationBillDtl.is_payment = true;
            }

            this.SignatureVisible = false;
          } else {
            Site.Modal.Alert(data.ErrMsg);
          }
        })
        .finally(() => {
          this.ConfirmingPayment = false;
          this.HideLoading();
        });
    },
    //保存报价单
    SaveQuotationBill(status, callback) {
      if (!this.Form.user_name) {
        Site.Modal.Alert("请输入客户姓名");
        return false;
      }

      if (!this.Form.user_tel) {
        Site.Modal.Alert("请输入手机号");
        return false;
      }

      if (!/^[1][0-9]{10}$/.test(this.Form.user_tel)) {
        Site.Modal.Alert("请输入正确的手机号");
        return false;
      }

      if (!this.Form.sales_employee_id) {
        Site.Modal.Alert("请选择销售顾问");
        return false;
      }

      if (!this.Form.province_id || !this.Form.city_id || !this.Form.county_id) {
        Site.Modal.Alert("请选择发货地址");
        this.ShowMoreForm = true;
        return;
      }

      if (!this.Form.address) {
        Site.Modal.Alert("请输入详细地址");
        this.ShowMoreForm = true;
        return;
      }

      //用户签名图片需要处理，只要/Upload/后的部分
      let _userSignedPicPath = this.QuotationBillDtl.user_signed_pic_path;
      if (_userSignedPicPath && _userSignedPicPath.indexOf("Cg/Upload/") >= 0) {
        _userSignedPicPath = _userSignedPicPath.substring(
          _userSignedPicPath.indexOf("/Upload/")
        );
      }

      this.ShowLoading("正在保存报价单...");

      Cgl.Net.Request.CallEasyItf("Rzs_UpdateQuotationBill", {
        quotation_bill_id: this.QuotationBillID,

        user_name: this.Form.user_name,
        user_tel: this.Form.user_tel,
        user_wx: this.Form.user_wx,
        user_email: this.Form.user_email,
        sales_employee_id: this.Form.sales_employee_id,
        province_id: this.Form.province_id,
        city_id: this.Form.city_id,
        county_id: this.Form.county_id,
        about_send_date: this.Form.about_send_date,
        address: this.Form.address,
        remark: this.Form.remark,

        is_payment: this.QuotationBillDtl.is_payment ? "1" : "0",
        user_signed_pic_path: _userSignedPicPath,
        status: this.QuotationBillDtl.is_payment
          ? 3
          : status || this.QuotationBillDtl.status,
      }).then((data) => {
        if (data.IsSuccess == "1") {
          if (callback) {
            callback();
          } else {
            this.GetQuotationBillDtl();
          }
        } else {
          Site.Modal.Alert(data.ErrMsg);
        }
      });
    },
    //保存并生成PDF
    SaveAndGeneratePDF() {
      let _generatePDF = () => {
        this.ShowLoading("报价单PDF生成中...");

        Cgl.Net.Request.CallEasyItf("Rzs_CreateQuotationBillPDF", {
          quotation_bill_id: this.QuotationBillID,
          choose_goods_ids: this.SelectedGoodsKeys.join(","),
        }).then((data) => {
          if (data.IsSuccess == "1") {
            this.GetQuotationBillDtl().then(() => {
              this.PDFQRCodeVisible = true;
            });
          } else {
            this.HideLoading();
            Site.Modal.Alert(data.ErrMsg);
          }
        });
      };

      //不允许保存，就直接生成PDF，否则要先保存
      if (!this.IsAllowSave) {
        _generatePDF();
      } else {
        this.SaveQuotationBill(2, () => _generatePDF());
      }
    },
    //提交报价单申报
    SubmitQuotationBillDeclare() {
      this.ShowLoading();

      if (this.SubmitDeclareLock) {
        return;
      }

      this.SubmitDeclareLock = true;

      Cgl.Net.Request.CallEasyItf("Rzs_SubmitQuotationBillDeclare", {
        quotation_bill_id: this.QuotationBillID,
      })
        .then((data) => {
          if (data.IsSuccess == "1") {
            this.ShowDeclareQRCodeModal(data);
          } else {
            Site.Modal.Alert(data.ErrMsg);
          }
        })
        .finally(() => {
          this.SubmitDeclareLock = false;
          this.HideLoading();
        });
    },
    //显示申报二维码
    ShowDeclareQRCodeModal(data) {
      this.DeclareData = data;

      this.DeclareQRCodeVisible = true;
    },
  },
};
</script>
<style>
.quotation-bill-dtl-warp .ant-form-item {
  margin-bottom: 0.5rem;
}

.quotation-bill-dtl-warp .ant-page-header-footer {
  margin-top: 0;
}
</style>

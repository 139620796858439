<template>
    <div class="goods-category-warp cg-page">

        <goods-header />

        <main class="category1-list cg-container">
            <a-spin :spinning="PageLoading">
                <a-skeleton :loading="PageLoading" >
                    <div class="category1-item" v-for="(item1, index) in CategoryList" :key="index">
                        <div class="title">
                            <img :src="item1.icon" />
                            <span>{{ item1.category_desc }}</span>
                        </div>
                        <div class="category2-list">
                            <a-list :grid="{ gutter: 16, xs: 1, sm: 2, md: 3, lg: 3, xl: 4, xxl: 6 }" :data-source="item1.data">
                                <a-list-item slot="renderItem" slot-scope="item" style="margin-bottom: .5rem;">
                                    <a :href="'#/goodslist?category_id=' + item.category_id">
                                        <a-card>
                                            <cg-bg-img slot="cover" :src="item.photo_url" :width="350" :height="230" type="cover" />
                                        </a-card>
                                        <div class="desc">{{ item.category_desc }}</div>
                                    </a>
                                </a-list-item>
                            </a-list>
                        </div>
                    </div>
                </a-skeleton>
            </a-spin>
        </main>

        <quotation-bill-affix v-if="ShopList&&ShopList.length<=0" />
    </div>
</template>
<script>
import QuotationBillAffix from "../../components/QuotationBillAffix.vue";
import GoodsHeader from "../../components/GoodsHeader.vue";
import CgBgImg from "../../components/base/BgImg.vue";
import Site from "../../core";

export default {
    components: { QuotationBillAffix, GoodsHeader, CgBgImg },
    data() {

        return {
            CategoryList: [],
            PageLoading: true,
            ShopList:Site.ShopList
        }
    },
    async created() {

        await Site.VerifyUserLogin();
        this.GetCategoryList();
        this.ShopList=Site.ShopList
    },
    computed: {
        // ShopList(){
        //     return Site.ShopList
        // }
    },
    methods: {
        GetCategoryList() {

            Site.GetCategoryList().then((list)=>{

                this.CategoryList = list;

                this.PageLoading = false;
            });
        }
    }
}
</script>
<style>

.goods-category-warp .category1-item {
    margin-top: 1.5rem;
}

.category1-list .title {
    color: #20388a;
    font-size: 1.8rem;
    line-height: 2rem;
    height: 2rem;
    font-weight: bold;
}

.category1-list .title img {
    width: 2rem;
    position: absolute;
}
.category1-list .title span{
    margin-left: 2.5rem;
}

.category2-list {
    margin-top: 1rem;
}

.category2-list .desc {
    margin-top: 1rem;
    font-size: 1.125rem;
    font-weight: bold;
}

</style>
<template>
    <cg-header class="goods-header">

        <div slot="left" style="text-align: right;">
            <a-button class="cg-header-button" type="link" size="large" icon="gold" @click="ShowGoodsDrawer">
                <!-- <img src="images/khcfdc-icon.png" style="width: 1.625rem; position: absolute;" /> -->
                <!-- <span style="margin-left: 2rem;">产品</span> -->
                产品
            </a-button>
        </div>

        <div slot="center" style="text-align: center;">
            <a-input v-model="Keyword" size="large" placeholder="请输入搜索关键字" 
                style="width: 80%;" @focus="ShowSearchDrawer">
                <a-icon slot="prefix" type="search" />
            </a-input>
        </div>

        <div slot="right">
            <a-button class="cg-header-button" type="link" size="large" icon="file-search" @click="ToQuotationBillList">
                查询订单
            </a-button>
        </div>

        <a-drawer placement="top" :visible="GoodsDrawerVisible" :destroyOnClose="true" :zIndex="998"
            :wrapStyle="WrapStyle" height="100%" @close="CloseGoodsDrawer">

            <a-row class="category1-list" style="margin: 0 0 4.6875rem;">
                <a-col :span="10" :offset="1" v-for="(item1, index1) in CategoryList" :key="index1">
                    <div class="category1-desc" 
                        style="font-size: 1.5rem;font-weight: bold;border-bottom: 1px solid #ccc;line-height: 3rem;">
                        {{ item1.category_desc }}
                    </div>

                    <a-row style="margin-top:1rem;">
                        <a-col :span="12" v-for="(item2, index2) in item1.data" :key="index2">
                            <div @click="ClickCategory(item2)" style="width: 90%; line-height: 1.5rem; font-weight: bold; margin-bottom:1.5rem;">
                                {{ item2.category_desc }}
                            </div>
                        </a-col>
                    </a-row>
                </a-col>
            </a-row>

        </a-drawer>

        <a-drawer placement="top" :visible="SearchDrawerVisible" :destroyOnClose="true" :zIndex="998" height="100%"
            @close="CloseSearchDrawer" :wrapStyle="WrapStyle">
            <div v-show="SearchLableList.length >0 || SearchCategoryList.length >0">
                <div style="color: #999; line-height: 2rem;">你想查询的是？</div>
                <div style="line-height: 2rem;">
                    <span class="category-item" v-for="(item, index) in SearchCategoryList" :key="'category' + index" 
                        style="margin-right:2rem;" @click="ShowCategoryGoods(item)">
                        {{ item.category_desc }}
                    </span>
                    <span class="label-item" v-for="(item, index) in SearchLableList" :key="'label' + index" 
                        style="margin-right:2rem;" @click="ShowLabelGoods(item)">
                        # {{ item.lable_desc }}
                    </span>
                </div>
            </div>
            <a-list :grid="{ gutter: 16, xs: 1, sm: 2, md: 3, lg: 3, xl: 4, xxl: 6 }" 
                style="margin: 1.25rem 0 4.6875rem;"
                :data-source="SearchGoodsList">
                <a-list-item slot="renderItem" slot-scope="item">
                    <goods-card :goods="item" :isShowImg="false" style="height: 10rem;"/>
                </a-list-item>
            </a-list>
        </a-drawer>
        
    </cg-header>
</template>
<script>
import CgHeader from "./base/Header";
import GoodsCard from "./GoodsCard.vue";

export default {
    name: "goods-header",
    components: { CgHeader, GoodsCard },
    data() {
        return {
            Keyword: "",
            GoodsDrawerVisible: false,
            SearchDrawerVisible: false,
            WrapStyle: { top: '4.7rem' },
            CategoryList: [],
            SearchGoodsList: [],
            SearchLableList: [],
            SearchCategoryList: []
        }
    },
    watch: {
        Keyword() {

            clearTimeout(this.KeywordTimeout);

            this.KeywordTimeout = setTimeout(()=>{
                this.GetSearchResult(this.Keyword);
            }, 100);
        }
    },
    created() {
        this.GetCategoryList();
    },
    methods: {
        ShowGoodsDrawer() {
            this.GoodsDrawerVisible = !this.GoodsDrawerVisible;
            this.CloseSearchDrawer();
        },
        CloseGoodsDrawer() {
            this.GoodsDrawerVisible = false;
        },
        ShowSearchDrawer() {
            this.SearchDrawerVisible = true;
            this.CloseGoodsDrawer();
        },
        CloseSearchDrawer() {
            this.SearchDrawerVisible = false;
        },
        //获取分类列表
        GetCategoryList() {

            Site.GetCategoryList().then((list)=>{
                this.CategoryList = list;
            });
        },
        //点击分类
        ClickCategory(category) {

            this.$router.push(`/goodslist?category_id=${category.category_id}`);

            this.CloseGoodsDrawer();
        },
        //获取商品列表
        GetSearchResult(content) {

            if(content !== "") {

                Cgl.Net.Request.CallEasyItf("Rzs_GetSearchResult", {
                    key_word: content
                }).then((data)=>{

                    if(data.IsSuccess == "1") {
                        this.SearchGoodsList = data.GoodsList || [];
                        this.SearchLableList = data.LableList || [];
                        this.SearchCategoryList = data.CategoryList || [];
                    }
                });
            }
            else {
                this.SearchGoodsList = [];
                this.SearchLableList = [];
                this.SearchCategoryList = [];
            }
        },
        //到订单列表
        ToQuotationBillList() {
            Site.ToQuotationBillList();
        },
        //显示分类商品
        ShowCategoryGoods(item) {
            this.CloseSearchDrawer();
            Site.ToGoodsList(item.category_id);
        },
        //显示标签商品
        ShowLabelGoods(item) {
            this.CloseSearchDrawer();
            Site.ToGoodsList("", item.lable_id);
        }
    }
}
</script>
<style>

</style>
<template>
    <div class="header-warp">

        <a class="logo" href="#/"><img src="images/logo.png" /></a>

        <div class="content">
            <a-row>
                <a-col :span="4" :offset="2">
                    <slot name="left"></slot>
                </a-col>
                <a-col :span="12">
                    <slot name="center"></slot>
                </a-col>
                <a-col :span="4">
                    <slot name="right"></slot>
                </a-col>
                <a-col :span="2" style="text-align: right;">
                    <a-button class="cg-header-button" type="link" icon="logout" size="large" @click="ExitLogin">
                        退出
                    </a-button>
                </a-col>
            </a-row>
        </div>

        <slot></slot>
    </div>
</template>
<script>
import Site from '../../core';

export default {
    name: "cg-header",
    methods: {
        ExitLogin() {

            Site.Modal.Confirm("确认退出登录？", ()=>{

                Cgl.Net.Request.CallEasyItf("Rzs_UserExitLogin").then((data) => {
Site.ShopID=""
Site.ShopList=[]
                    if(data.IsSuccess === "1") {
                        Site.ToLogin(location.href);
                    }
                    else {
                        Site.Modal.Alert(data.ErrMsg);
                    }
                });
            });
        }
    }
}
</script>
<style>
.header-warp {
    background: #fff;
    height: 4.6875rem;
    box-shadow: 0 1px 4px rgb(0 21 41 / 8%);
    border-bottom: 1px solid rgb(235, 237, 240);
    line-height: 4.6875rem;
    z-index: 999;
    position: fixed;
    top: 0;
    width: 100%;
}

.header-warp .content {
    margin: 0 1.875rem;
    height: 100%;
}

.header-warp .logo {
    width: 3.75rem;
    position: absolute;
    left: 1.875rem;
    z-index: 1;
}

.header-warp .logo img {
    width: 100%;;
}
</style>
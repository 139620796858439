import Cgl from "Cgl";
import Modal from "./Modal";
import Msg from "./Msg";

const Site = {
    Modal, Msg,
    //缓存的用户信息
    UserInfo: {},
    //缓存的分类列表
    CategoryList: [],
    //缓存的销售顾问列表
    SalesEmployeeList: [],
    //缓存的销售下属门店列表
    ShopList:[],
    ShopID:"",
    //报价单代码
    AffixQuotationBillID: "",
    //格式化钱
    FormatMoney(num) {

        if(isNaN(num)) { num = 0; }
        num = typeof num == "string"?parseFloat(num):num//判断是否是字符串如果是字符串转成数字
        num = num.toFixed(2);//保留两位
        num = parseFloat(num);//转成数字
        num = num.toLocaleString();//转成金额显示模式
        //判断是否有小数
        if(num.indexOf(".")==-1){
            num = num+".00";
        }else{
            num = num.split(".")[1].length<2 ? num+"0" : num;
        }
        return num;//返回的是字符串23,245.12保留2位小数
    },
    //获取用户信息
    GetUserInfo(isReload) {

        return new Promise(function (resolve) {

            if (!isReload && !Cgl.Object.IsEmptyObject(Site.UserInfo)) {
                resolve(Site.UserInfo);
            } 
            else {

                Cgl.Net.Request.CallEasyItf("Rzs_IsLogin").then(function (data) {
                    if (data.is_login == "1") {
                        Site.UserInfo = data || {};
                        Site.ShopList=data.shop_list||[]
                        console.log('Site.ShopList',Site.ShopList);
                    }
                }).finally(function () {
                    resolve(Site.UserInfo);
                });
            }
        });
    },
    //验证用户登录
    VerifyUserLogin() {

        return new Promise((resolve) => {

            let _isLogin = true;

            this.GetUserInfo().then((userInfo) => {

                if(!userInfo || Cgl.Object.IsEmptyObject(userInfo)) {
                    _isLogin = false;
                    Site.ToLogin();
                    return;
                }

                resolve(_isLogin);
            });
        });
    },
    //获取分类列表
    GetCategoryList(isReload) {

        return new Promise((resolve) => {

            if (!isReload && !Cgl.Object.IsEmptyObject(Site.CategoryList)) {
                resolve(Site.CategoryList);
            } 
            else {
    
                Cgl.Net.Request.CallEasyItf("Rzs_GetCategoryList").then(function (data) {
                    if (data.IsSuccess == "1") {
                        Site.CategoryList = data.category_list || [];
                    }
                }).finally(function () {
                    resolve(Site.CategoryList);
                });
            }
        });
    },
    //获取销售员列表
    GetSalesEmployeeList(isReload,shopID) {

        return new Promise((resolve) => {

            if (!isReload && !Cgl.Object.IsEmptyObject(Site.SalesEmployeeList)) {
                resolve(Site.SalesEmployeeList);
            } 
            else {
    
                Cgl.Net.Request.CallEasyItf("Rzs_GetSalesEmployeeList",{shop_id:shopID||''}).then(function (data) {
                    if (data.IsSuccess == "1") {
                        Site.SalesEmployeeList = data.data || [];
                    }
                }).finally(function () {
                    resolve(Site.SalesEmployeeList);
                });
            }
        });
    },
    //获取新的报价单详情
    GetNewQuotationBillDtl(data) {

        return Object.assign({
            "quotation_bill_id": "",
            "quotation_bill_no": "",
            "user_name": "",
            "user_tel": "",
            "user_wx": "",
            "user_email": "",
            "status": "0",
            "total_amount": "",
            "sales_employee_id": "",
            "sales_employee_name": "",
            "cmn_createdate": "",
            "good_list": [],
            "cmn_createdate": "",
            "shop_name": "",
            "is_payment": "False",
            "user_signed_pic_path": "",
            "rdy_quotation_pdf_path": "",
            "rdy_quotation_pdf_qrcode": ""
        },data || {});
    },
    ToLogin() {
        this.UserInfo={}
         //缓存的分类列表
    this.CategoryList= [],
    //缓存的销售顾问列表
    this.SalesEmployeeList= [],
    //缓存的销售下属门店列表
    this.ShopList=[],
    this.ShopID="",
    //报价单代码
    this.AffixQuotationBillID= "",
        Router.push("/login");
    },
    ToGoodsList(categoryID, labelID) {

        let _url = "/goodslist";

        if(categoryID) {
            _url = Cgl.Url.AddParamToUrl(_url, `category_id=${categoryID}`);
        }

        if(labelID) {
            _url = Cgl.Url.AddParamToUrl(_url, `label_id=${labelID}`);
        }

        Router.push(_url);
    },
    ToGoodsCategory() {
        Router.push("/");
    },
    ToQuotationBillList(keyword) {

        let _url = "/quotationbilllist";

        if(keyword) {
            _url = Cgl.Url.AddParamToUrl(_url, `keyword=${keyword}`);
        }

        Router.push(_url);
    },
    ToQuotationBillDtl(quotationBillID) {
        Router.push(`/quotationbill?quotation_bill_id=${quotationBillID}`);
    }
};

export default Site;
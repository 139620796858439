<template>
    <a-badge class="quotation-bill-status" :status="StatusMap[status].Status" :text="StatusMap[status].Desc" />
</template>
<script>
export default {
    name: "quotation-bill-status",
    props: {
        status: {
            type: String | Number,
            default: "0"
        }
    },
    data() {
        return {
            StatusMap: {
                "0": { Desc: "待处理", Status: "default" },
                "1": { Desc: "暂存", Status: "default" },
                "2": { Desc: "已导出", Status: "processing" },
                "3": { Desc: "已确认", Status: "success" },
                "4": { Desc: "已作废", Status: "error" },
            }
        }
    }
}
</script>
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import Antd from 'ant-design-vue';
import Site from './core';
import 'ant-design-vue/dist/antd.less';
import './antd-variables.less';

Vue.config.productionTip = false;

Vue.use(Antd);

window.Cmn = Site;
window.Site = Site;

Vue.prototype.FormatMoney = function (num){
	return Site.FormatMoney(num);
}

new Vue({
	router,
	render: h => h(App),
}).$mount('#app');

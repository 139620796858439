<template>
    <div class="goods-list-warp cg-page">
        <goods-header />

        <div v-if="CategoryID" class="breadcrumb-warp">
            <a-breadcrumb>
                <a-breadcrumb-item><a href="#/">产品</a></a-breadcrumb-item>
                <a-breadcrumb-item>{{ ParentCategory.category_desc }}</a-breadcrumb-item>
                <a-breadcrumb-item>{{ CurCategory.category_desc }}</a-breadcrumb-item>
            </a-breadcrumb>
        </div>

        <main class="cg-container">
            <a-spin :spinning="PageLoading">
                <a-skeleton :loading="PageLoading" >
                    <a-list :grid="{ gutter: 16, xs: 1, sm: 2, md: 3, lg: 3, xl: 4, xxl: 6 }" 
                        style="margin-top: 1.25rem;"
                        :data-source="GoodsList">
                        <a-list-item slot="renderItem" slot-scope="item">
                            <goods-card :goods="item" style="height: 23rem;"/>
                        </a-list-item>
                        <div v-if="ShowLoadingMore"
                            slot="loadMore"
                            :style="{ textAlign: 'center' }"
                        >
                            <a-spin v-if="LoadingMore" />
                            <a-button size="large" v-else @click="LoadMoreGoods">
                                加载更多
                            </a-button>
                        </div>
                    </a-list>
                </a-skeleton>
            </a-spin>
        </main>

        <quotation-bill-affix v-if="ShopList&&ShopList.length<=0" />
    </div>
</template>
<script>
import QuotationBillAffix from "../../components/QuotationBillAffix.vue";
import GoodsHeader from "../../components/GoodsHeader.vue";
import GoodsCard from "../../components/GoodsCard.vue";
import Site from "../../core";

export default {
    components: { QuotationBillAffix, GoodsHeader, GoodsCard },
    data() {
        return {
            PageLoading: true,
            LoadingMore: false,
            GoodsList: [],
            CategoryID: "",
            CurCategory: { category_desc: "" },
            ParentCategory: { category_desc: "" },
            DataPaging: {
                CurPage: 1,
                PageSize: 12,
                RecCount: 0
            },
            ShopList:Site.ShopList
        }
    },
    computed: {
        ShowLoadingMore() {
            return this.DataPaging.RecCount > this.GoodsList.length;
        }
    },
    watch: {
        "$route": function() {

            this.InitData();
        }
    },
    async created() {

        await Site.VerifyUserLogin();
        
        this.InitData();
        this.ShopList=Site.ShopList
    },
    methods: {
        InitData() {

            this.CategoryID = this.$route.query.category_id || "";
            this.LableID = this.$route.query.label_id || "";

            this.GetGoodsList();
            this.GetBreadcrumb();
        },
        //获取商品列表
        GetGoodsList(isAppend) {

            if(isAppend) { this.LoadingMore = true; }
            else { this.PageLoading = true; }

            Cgl.Net.Request.CallEasyItf("Rzs_GetGoodsList", {
                category_id: this.CategoryID,
                lable_id: this.LableID,
                CurPage: this.DataPaging.CurPage,
                PageSize: this.DataPaging.PageSize
            }).then((data)=>{

                if(data.IsSuccess == "1") {

                    if(!isAppend) { this.GoodsList = data.data || []; }
                    else { this.GoodsList = this.GoodsList.concat(data.data || []); }
         
                    this.DataPaging.RecCount = (data.RecCount || 0) * 1;
                }
                else {
                    Site.Modal.Alert(data.ErrMsg);
                }
            }, ()=>{
                Site.Modal.Alert("网络问题，请刷新后重试");
            }).finally(()=>{
                this.LoadingMore = this.PageLoading = false;
            });
        },
        //加载更多商品
        LoadMoreGoods() {

            this.DataPaging.CurPage++;

            this.GetGoodsList(true);
        },
        //获取面包屑
        GetBreadcrumb() {

            this.CategoryID && Site.GetCategoryList().then((list)=>{
                
                for(let _i=0; _i<list.length; _i++) {

                    let _pCategory = list[_i];
                    let _curCategory = _pCategory.data.find(item => item.category_id === this.CategoryID);
                    if(_curCategory) {
                        this.ParentCategory = _pCategory;
                        this.CurCategory = _curCategory;
                        break;
                    }
                }
            });
        }
    }
}
</script>
<style>

.goods-list-warp .breadcrumb-warp {
    border: 1px solid rgb(235, 237, 240);
    background: #fff;
    padding: 1rem 1.5rem;
}

</style>
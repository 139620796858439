<template>
  <div class="quotation-bill-list-warp cg-page">
    <quotation-bill-header />

    <div style="border: 1px solid rgb(235, 237, 240); background: #fff">
      <div class="container">
        <a-tabs v-model="ActiveStatus" :tabBarStyle="TabBarStyle">
          <a-tab-pane v-for="item in StatusList" :key="item.Status" :tab="item.Desc" />
          <template slot="tabBarExtraContent" v-if="ShopList && ShopList.length > 0">
            所属门店：
            <a-select v-model="ShopID" style="width: 120px">
              <a-select-option value=""> 全部 </a-select-option>
              <a-select-option
                v-for="(item, index) in ShopList"
                :key="index"
                :value="item.shop_id"
              >
                {{ item.shop_name }}
              </a-select-option>
            </a-select>
          </template>
          <template slot="tabBarExtraContent">
            销售顾问：
            <a-select v-model="SalesEmployeeID" style="width: 120px">
              <a-select-option value=""> 全部 </a-select-option>
              <a-select-option
                v-for="(item, index) in SalesEmployeeList"
                :key="index"
                :value="item.sales_employee_id"
              >
                {{ item.sales_employee_name }}
              </a-select-option>
            </a-select>
          </template>
        </a-tabs>
      </div>
    </div>

    <main class="cg-container cg-main">
      <a-spin :spinning="PageLoading">
        <a-table
          size="small"
          :pagination="false"
          :columns="Columns"
          :data-source="QuotationBillList"
          :row-key="(record, index) => index"
          @change="OnTableChange"
        >
          <div slot="user_name" slot-scope="text, record" style="white-space: nowrap">
            {{ record.user_name }}
          </div>

          <div
            slot="sales_employee_name"
            slot-scope="text, record"
            style="white-space: nowrap"
          >
            {{ record.sales_employee_name }}
          </div>

          <div slot="user_tel" slot-scope="text, record" style="white-space: nowrap">
            <div>
              <a-icon v-show="record.user_tel" type="mobile" /> {{ record.user_tel }}
            </div>
            <div>
              <a-icon v-show="record.user_wx" type="wechat" /> {{ record.user_wx }}
            </div>
          </div>

          <div slot="total_amount" slot-scope="text, record" style="white-space: nowrap">
            ￥ {{ FormatMoney(record.total_amount) }}
          </div>

          <div slot="status" slot-scope="text, record" style="white-space: nowrap">
            <quotation-bill-status :status="record.status" />
          </div>

          <template slot="——history——" slot-scope="text, record">
            <a-button
              type="link"
              icon="history"
              size="small"
              @click="ShowHistoryDrawer(record)"
            >
            </a-button>
          </template>

          <div slot="——action——" slot-scope="text, record" style="white-space: nowrap">
            <a-button
              v-show="record.status != '4'"
              type="link"
              icon="form"
              size="small"
              @click="EditQuotationBill(record)"
            >
            </a-button>
            <a-button
              v-show="
                !(record.status == '3' || record.status == '4') &&
                ShopList &&
                ShopList.length <= 0
              "
              icon="shopping-cart"
              type="link"
              @click="CopyQuotationBill(record)"
            >
            </a-button>
          </div>
        </a-table>
      </a-spin>
    </main>

    <cg-footer>
      <template slot="right">
        <a-pagination
          v-model="DataPaging.CurPage"
          show-size-changer
          :total="DataPaging.RecCount"
          :pageSize="DataPaging.PageSize"
          @showSizeChange="OnShowSizeChange"
        />
      </template>
    </cg-footer>

    <a-drawer
      placement="right"
      :visible="HistoryVisible"
      :destroyOnClose="true"
      :width="'90%'"
      @close="CloseHistoryDrawer"
    >
      <template slot="title">
        <span style="font-size: 1.5rem; font-weight: bold">报价单</span>
        <span v-show="ViewQuotationBill.quotation_bill_id" style="margin-left: 2rem">
          ID：{{ ViewQuotationBill.quotation_bill_no }}
        </span>
      </template>
      <a-table
        size="small"
        :pagination="false"
        :columns="HistoryColumns"
        :data-source="HistoryRecList"
        :row-key="(record, index) => index"
      >
        <div slot="user_name" slot-scope="text, record" style="white-space: nowrap">
          {{ record.user_name }}
        </div>

        <div
          slot="sales_employee_name"
          slot-scope="text, record"
          style="white-space: nowrap"
        >
          {{ record.sales_employee_name }}
        </div>

        <div slot="user_tel" slot-scope="text, record" style="white-space: nowrap">
          <div>
            <a-icon v-show="record.user_tel" type="mobile" /> {{ record.user_tel }}
          </div>
          <div><a-icon v-show="record.user_wx" type="wechat" /> {{ record.user_wx }}</div>
        </div>

        <div slot="total_amount" slot-scope="text, record" style="white-space: nowrap">
          ￥ {{ FormatMoney(record.total_amount) }}
        </div>

        <div slot="status" slot-scope="text, record" style="white-space: nowrap">
          <quotation-bill-status :status="record.status" />
        </div>

        <div slot="——action——" slot-scope="text, record" style="white-space: nowrap">
          <a-button
            v-show="record.status != '4'"
            type="link"
            icon="form"
            size="small"
            @click="EditQuotationBill(record)"
          >
          </a-button>
          <a-button
            v-show="
              !(record.status == '3' || record.status == '4') &&
              ShopList &&
              ShopList.length <= 0
            "
            icon="shopping-cart"
            type="link"
            @click="CopyQuotationBill(record)"
          >
          </a-button>
        </div>
      </a-table>
    </a-drawer>
  </div>
</template>
<script>
import QuotationBillHeader from "../../components/QuotationBillHeader";
import QuotationBillStatus from "../../components/QuotationBillStatus";
import CgFooter from "../../components/base/Footer";
import Site from "../../core";

const Columns = [
  {
    title: "ID",
    dataIndex: "quotation_bill_no",
    scopedSlots: { customRender: "quotation_bill_no" },
    align: "center",
  },
  {
    title: "客户名",
    dataIndex: "user_name",
    scopedSlots: { customRender: "user_name" },
  },
  {
    title: "销售顾问",
    dataIndex: "sales_employee_name",
    scopedSlots: { customRender: "sales_employee_name" },
  },
  {
    title: "手机号与微信",
    dataIndex: "user_tel",
    scopedSlots: { customRender: "user_tel" },
  },
  {
    title: "报价金额",
    dataIndex: "total_amount",
    scopedSlots: { customRender: "total_amount" },
  },
  {
    title: "状态",
    dataIndex: "status",
    scopedSlots: { customRender: "status" },
  },
  {
    title: "日期",
    dataIndex: "cmn_CreateDate",
    scopedSlots: { customRender: "cmn_CreateDate" },
    sorter: true,
  },
  {
    title: "历史",
    dataIndex: "——history——",
    scopedSlots: { customRender: "——history——" },
  },
  {
    title: "操作",
    dataIndex: "——action——",
    scopedSlots: { customRender: "——action——" },
  },
];

const HistoryColumns = Columns.filter((item) => item.dataIndex !== "——history——").map(
  (item) => {
    let _newItem = JSON.parse(JSON.stringify(item));

    if (_newItem.dataIndex == "cmn_CreateDate") {
      _newItem.sorter = (a, b) => new Date(a.cmn_CreateDate) - new Date(b.cmn_CreateDate);
    }

    return _newItem;
  }
);

export default {
  components: { QuotationBillHeader, QuotationBillStatus, CgFooter },
  data() {
    return {
      //页面Loading
      PageLoading: false,
      //激活的状态
      ActiveStatus: "",
      TabBarStyle: {
        margin: 0,
        border: "none",
      },
      SalesEmployeeID: "",
      ShopID: "",
      SalesEmployeeList: [],
      Columns: Columns,
      HistoryColumns: HistoryColumns,
      StatusList: [
        { Desc: "全部", Status: "" },
        { Desc: "暂存", Status: "1" },
        { Desc: "已导出", Status: "2" },
        { Desc: "已确认", Status: "3" },
        { Desc: "已作废", Status: "4" },
      ],
      QuotationBillList: [],
      ViewQuotationBill: {
        quotation_bill_id: "",
        quotation_bill_no: "",
      },
      HistoryRecList: [],
      DataPaging: {
        CurPage: 1,
        PageSize: 10,
        RecCount: 0,
        SortBy: "",
      },
      HistoryVisible: false,
      ShopList: Site.ShopList,
    };
  },
  computed: {
    // ShopList(){
    //     return Site.ShopList
    // }
  },
  watch: {
    ActiveStatus() {
      this.GetQuotationBillList();
    },
    SalesEmployeeID() {
      this.GetQuotationBillList();
    },
    ShopID() {
      this.GetQuotationBillList();
      Site.GetSalesEmployeeList(true, this.ShopID).then((list) => {
        this.SalesEmployeeList = list;
        Site.ShopID = this.ShopID;
        this.SalesEmployeeID = "";
      });
    },
    "DataPaging.CurPage": function () {
      this.GetQuotationBillList();
    },
    $route: function () {
      this.GetQuotationBillList();
    },
  },
  async created() {
    await Site.VerifyUserLogin();

    this.GetQuotationBillList();

    Site.GetSalesEmployeeList(true, this.ShopID).then((list) => {
      this.SalesEmployeeList = list;
    });
    console.log("this.ShopList", this.ShopList);
    this.ShopList = Site.ShopList;
  },
  methods: {
    //显示历史
    ShowHistoryDrawer(data) {
      this.ViewQuotationBill = data;

      this.GetQuotationBillHistoryRec(data, () => {
        this.HistoryVisible = true;
      });
    },
    //关闭历史
    CloseHistoryDrawer() {
      this.HistoryVisible = false;
    },
    OnShowSizeChange(current, size) {
      this.DataPaging.PageSize = size;

      if (this.DataPaging.CurPage == 1) {
        this.GetQuotationBillList();
      } else {
        this.DataPaging.CurPage = 1;
      }
    },
    OnTableChange(pagination, filters, sorter, { currentDataSource }) {
      if (!sorter) {
        this.DataPaging.SortBy = "";
      } else if (sorter.order === "ascend") {
        this.DataPaging.SortBy = `[${sorter.field}] asc`;
      } else if (sorter.order === "descend") {
        this.DataPaging.SortBy = `[${sorter.field}] desc`;
      }

      this.GetQuotationBillList();
    },
    //获取订单列表
    GetQuotationBillList() {
      this.PageLoading = true;

      Cgl.Net.Request.CallEasyItf("Rzs_GetQuotationBillList", {
        status: this.ActiveStatus,
        sales_employee_id: this.SalesEmployeeID,
        content: this.$route.query.keyword || "",
        CurPage: this.DataPaging.CurPage,
        PageSize: this.DataPaging.PageSize,
        SortBy: this.DataPaging.SortBy,
        shop_id: this.ShopID || "",
      })
        .then(
          (data) => {
            if (data.IsSuccess == "1") {
              console.log(data);
              this.QuotationBillList = data.data || [];
              this.DataPaging.RecCount = (data.RecCount || 0) * 1;
            } else {
              Site.Modal.Alert(data.ErrMsg);
            }
          },
          () => {
            Site.Modal.Alert("网络问题，请刷新后重试");
          }
        )
        .finally(() => {
          this.$nextTick(() => {
            this.PageLoading = false;
          });
        });
    },
    //获取报价单历史记录
    GetQuotationBillHistoryRec(data, callback) {
      Cgl.Net.Request.CallEasyItf("Rzs_GetQuotationBillHistoryRec", {
        quotation_bill_id: data.quotation_bill_id,
      }).then(
        (data) => {
          if (data.IsSuccess == "1") {
            this.HistoryRecList = data.data || [];

            callback && callback();
          } else {
            Site.Modal.Alert(data.ErrMsg);
          }
        },
        () => {
          Site.Modal.Alert("网络问题，请刷新后重试");
        }
      );
    },
    //编辑报价单
    EditQuotationBill(data) {
      this.CloseHistoryDrawer();

      Site.ToQuotationBillDtl(data.quotation_bill_id);
    },
    //复制报价单并选购
    CopyQuotationBill(data) {
      Cgl.Net.Request.CallEasyItf("Rzs_QuotationBillCopy", {
        quotation_bill_id: data.quotation_bill_id,
        type: 2,
      }).then(
        (data) => {
          if (data.IsSuccess == "1") {
            this.CloseHistoryDrawer();

            Site.AffixQuotationBillID = data.quotation_bill_info.quotation_bill_id;

            Site.ToGoodsCategory();
          } else {
            Site.Modal.Alert(data.ErrMsg);
          }
        },
        () => {
          Site.Modal.Alert("网络问题，请刷新后重试");
        }
      );
    },
  },
};
</script>
<style></style>

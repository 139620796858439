<template>
    <div class="bg-img-warp" :style="Style"></div>
</template>
<script>
export default {
    name: "cg-bg-img",
    props: {
        src: {
            type: String
        },
        width: {
            type: Number,
            default: 1
        },
        height: {
            type: Number,
            default: 1
        },
        type: {
            type: String,
            default: "contain"
        }
    },
    data() {

        return {
            Style: {}
        }
    },
    watch: {
        src() {
            this.InitStyle();
        }
    },
    methods: {
        InitStyle() {

            let _scale = this.height / this.width;

            let _style = {
                // width: this.$el.offsetWidth + "px",
                height: (this.$el.offsetWidth * _scale) + "px",
                background: `url('${this.src}')center center / ${this.type} no-repeat`
            }

            this.Style = _style;
        }
    },
    mounted() {

        this.$nextTick(()=>{ this.InitStyle(); });
    }
}
</script>
<style>

</style>
<template>
    <a-card class="goods-card-warp" size="small" @click="ShowGoodsDtlModal">

        <div v-if="isShowImg" slot="cover">
            <cg-bg-img :src="goods.pic_path" :width="350" :height="230" />
        </div>
        
        <a-row>
            <a-col :span="18">
                <div class="goods-desc-text">
                    {{ goods.goods_desc }}
                </div>
                <div class="goods-type-text">
                    {{ goods.type }}
                </div>
                <div>编号：{{ goods.goods_code }}</div>
                <div  class="goods-sell-price-text">
                    ￥ {{ FormatMoney(goods.sell_price) }}
                </div>
            </a-col>
            <a-col :span="6" style="text-align: right;" v-if="ShopList&&ShopList.length<=0">
                <a-button type="primary" size="large" shape="circle" icon="plus" 
                    style="margin-top: 1.5rem;"  @click.stop="ClickAddGoodsBtn"
                />
            </a-col>
        </a-row>

        <!--产品详情浮层-->
        <a-modal v-model="DtlModalVisible" width="800px">
            <a-row>
                <a-col :span="10">

                    <cg-bg-img :src="GoodsDtl.pic_path" />

                    <div class="tags" v-show="LableList.length > 0" style="margin-top: 1.5rem;">
                        <span style="font-size: 1.25rem; color: #40a9ff;">标签：</span>
                        <div class="tags-list">
                            <a-button type="primary" ghost style="margin: 0.5rem 1rem 0 0;" 
                                v-for="(item, index) in LableList" :key="index">
                                # {{ item.lable_desc }}
                            </a-button>
                        </div>
                    </div>

                    <!-- <div class="tags" v-show="GoodsTypeDtlList.length > 0" style="margin-top: 1.5rem;">
                        <span style="font-size: 1.25rem; color: #40a9ff;">
                            <span style="color:red;">*</span> 产品分类：
                        </span>
                        <div class="tags-list">
                            <a-button type="primary" style="margin: 0.5rem 1rem 0 0;" 
                                v-for="(item, index) in GoodsTypeDtlList" :key="index" 
                                :ghost="SelectedType.goods_type_dtl_id != item.goods_type_dtl_id"
                                @click="SelectGoodsType(item)">
                                {{ item.goods_type_dtl_desc }}
                            </a-button>
                        </div>
                    </div> -->

                    <div class="tags" v-show="ChildrenGoodsList.length > 0" style="margin-top: 1.5rem;">
                        <span style="font-size: 1.25rem; color: #40a9ff;">
                            <span style="color:red;">*</span> 产品分类：
                        </span>
                        <div class="tags-list">
                            <a-button type="primary" style="margin: 0.5rem 1rem 0 0;" 
                                v-for="(item, index) in ChildrenGoodsList" :key="index" 
                                :ghost="SelectedChild.goods_id != item.goods_id"
                                @click="SelectGoodsChild(item)">
                                {{ item.goods_desc }}
                            </a-button>
                        </div>
                    </div>
                </a-col>
                <a-col :span="11" :offset="1">
                    <div style="font-size: 1.25rem;font-weight: bold;">{{ GoodsDtl.goods_desc }}</div>
                    <div style="margin-top: 2rem;" v-html="GoodsDtl.detail"></div>
                </a-col>
            </a-row>
            <template slot="footer">
                <a-button v-show="GoodsDtl.goods_pdf_directions_path" 
                    type="primary" size="large" icon="file-pdf" 
                    @click="DownGoodsPDF">
                    产品说明书（PDF）
                </a-button>
                <a-button type="primary" size="large" icon="plus" style="margin-left: 1rem;"  v-if="ShopList.length<=0"
                    @click="AddQuotationGoods">
                    添加
                </a-button>
            </template>
        </a-modal>

        <!-- <a-modal v-model="TypeModalVisible" title="请选择产品分类" @ok="AddQuotationGoods">
            <div class="tags" v-show="GoodsTypeDtlList.length > 0">
                <span style="font-size: 1.25rem; color: #40a9ff;">
                    <span style="color:red;">*</span> 产品分类：
                </span>
                <div class="tags-list">
                    <a-button type="primary" style="margin: 0.5rem 1rem 0 0;" 
                        v-for="(item, index) in GoodsTypeDtlList" :key="index" 
                        :ghost="SelectedType.goods_type_dtl_id != item.goods_type_dtl_id"
                        @click="SelectGoodsType(item)">
                        {{ item.goods_type_dtl_desc }}
                    </a-button>
                </div>
            </div>
        </a-modal> -->

        <a-modal v-model="ChildModalVisible" title="请选择产品分类" @ok="AddQuotationGoods">
            <div class="tags">
                <!-- <span style="font-size: 1.25rem; color: #40a9ff;">
                    <span style="color:red;">*</span> 产品分类：
                </span> -->
                <div class="tags-list">
                    <a-button type="primary" style="margin: 0.5rem 1rem 0 0;" 
                        v-for="(item, index) in ChildrenGoodsList" :key="index" 
                        :ghost="SelectedChild.goods_id != item.goods_id"
                        @click="SelectGoodsChild(item)">
                        {{ item.goods_desc }}
                    </a-button>
                </div>
            </div>
        </a-modal>
    </a-card>
</template>
<script>

import Site from "../core";
import CgBgImg from "./base/BgImg.vue";

export default {
    name: "goods-card",
    components: { CgBgImg },
    props: {
        isShowImg: {
            type: Boolean,
            default: true
        },
        goods: {
            type: Object,
            default: ()=>{}
        }
    },
    data() {

        return {
            DtlModalVisible: false,
            GoodsDtl: {
                "goods_id":"",
                "goods_desc":"",
                "goods_code": "",
                "pic_path": "",
                "sell_price": "",
                "detail":"",
                "goods_pdf_directions_path": ""
            },
            LableList: [],
            // GoodsTypeDtlList: [],
            ChildrenGoodsList: [],
            // TypeModalVisible: false,
            ChildModalVisible: false,
            SelectedType: null,
            SelectedChild: null,
            Site:Site,
            ShopList:Site.ShopList
        }
    },
    methods: {
        GetGoodsDtl() {

            return new Promise((resolve)=>{
                
                Cgl.Net.Request.CallEasyItf("Rzs_GetGoodsDtl", {
                    goods_id: this.goods.goods_id
                }).then((data)=>{

                    if(data.IsSuccess == "1") {

                        Object.assign(this.GoodsDtl, data.GoodsDtl || {});

                        this.LableList = data.LableList || [];

                        this.ChildrenGoodsList = data.GoodsChildrenList || [];

                        if(this.ChildrenGoodsList.length > 0) {
                            this.SelectedChild = this.ChildrenGoodsList[0];
                        }

                        // this.GoodsTypeDtlList = data.GoodsTypeDtlList || [];

                        // if(this.GoodsTypeDtlList.length > 0) {
                        //     this.SelectedType = this.GoodsTypeDtlList[0];
                        // }

                        resolve();
                    }
                    else {
                        Site.Modal.Alert(data.ErrMsg);
                    }
                    
                }, ()=>{
                    Site.Modal.Alert("网络问题，请刷新后重试");
                });
            });
        },
        //显示商品详情浮层
        ShowGoodsDtlModal() {

            this.GetGoodsDtl().then(()=>{

                this.DtlModalVisible = true;
            });
        },
        //点击添加商品按钮
        ClickAddGoodsBtn() {

            this.GetGoodsDtl().then(()=>{

                // if(this.GoodsTypeDtlList.length > 0) {
                //     this.TypeModalVisible = true;
                // }
                if(this.ChildrenGoodsList.length > 0) {
                    this.ChildModalVisible = true;
                }
                else {
                    this.AddQuotationGoods();
                }
            });
        },
        // //选择商品类型
        // SelectGoodsType(type) {
        //     this.SelectedType = type;
        // },
        //选择子商品
        SelectGoodsChild(goods) {
            this.SelectedChild = goods;
        },
        //添加报价商品
        AddQuotationGoods() {

            let _curGoods = this.SelectedChild || this.goods;

            Cgl.Net.Request.CallEasyItf("Rzs_AddQuotationGoods", {
                quotation_bill_id: Site.AffixQuotationBillID,
                goods_id: _curGoods.goods_id, 
                // goods_type_dtl_id: this.SelectedType ? this.SelectedType.goods_type_dtl_id : "", 
                qty: 1 
            }).then((data)=>{

                if(data.IsSuccess == "1") {

                    // this.TypeModalVisible = false;
                    this.ChildModalVisible = false;
                    this.DtlModalVisible = false;

                    Site.Msg.Success('添加成功！');

                    if(this.goods.is_has_fitting == "1") {
                        this.GetGoodsFittingList(this.goods);
                    }
                }
                else {
                    Site.Modal.Alert(data.ErrMsg);
                }
            });
        },
        //获取商品配件列表
        GetGoodsFittingList(goods) {

            Cgl.Net.Request.CallEasyItf("Rzs_GetGoodsFittingList", {
                goods_id: goods.goods_id
            }).then((data)=>{

                if(data.IsSuccess == "1" && data.category_id) {

                    Site.Modal.Confirm("该商品存在配件，是否选购配件？", ()=>{
                        Site.ToGoodsList(data.category_id);
                    });
                }
            });
        },
        //下载商品PDF
        DownGoodsPDF() {
            window.open(this.GoodsDtl.goods_pdf_directions_path);
        }
    }
}
</script>
<style>
.goods-card-warp {
    cursor: pointer;
}
</style>

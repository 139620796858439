import Cgl from 'Cgl';
import { Modal } from 'ant-design-vue';

let _GetModalParams = (params, onOk, onCancel) => {

    params = params || {};

    if(Cgl.Object.IsType(params, "string")) {
        params = { content: params }
    }

    if(onOk && Cgl.Object.IsType(onOk, "function")) {
        params.onOk = onOk;
    }

    if(onCancel && Cgl.Object.IsType(onCancel, "function")) {
        params.onCancel = onCancel;
    }

    return params;
}

const Info = (params, onOk) => {
    Modal.info(_GetModalParams(params, onOk));
}

const Success = (params, onOk) => {
    Modal.success(_GetModalParams(params, onOk));
}

const Error = (params, onOk) => {
    Modal.error(_GetModalParams(params, onOk));
}

const Warning = (params, onOk) => {
    Modal.warning(_GetModalParams(params, onOk));
}

const Confirm = (params, onOk, onCancel) => {
    Modal.confirm(_GetModalParams(params, onOk, onCancel));
}

const Alert = (params, onOk) => {
    Info(params, onOk);
}


export default {
    Info,
    Success,
    Error,
    Warning,
    Confirm,
    Alert
}